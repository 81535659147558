import React from 'react';
import * as Papa from "papaparse"
import styled from 'styled-components'
import './App.css';
import Bkg from './bkg.svg'
import UskoLogo from './usko-logo.svg'
import AmazonLogo from './amazon-logo.svg'

interface Blah {
  "E-Mail": string
  "Pic": string
  "Timestamp": string
  "Amazon Spend": string
}

interface RawEntry {
  "Timestamp":string
  "Amazon 3-Year Spend":string
  "E-Mail":string
  "User Name (or alias)":string
  "Deleted":string
}
interface Entry {
  name: string
  spend: number
  idx: number
}

const sheetURL = "https://docs.google.com/spreadsheets/d/e/2PACX-1vS5QAzWs7W5osIwg4UUJkzAYicyYVMuu6VFprNZlPcr-0I1xxUsV9d_YQVrM7Rame8CMuRQDQxe4dWx/pub?gid=629597759&single=true&output=csv";

//https://docs.google.com/spreadsheets/d/e/2PACX-1vQDsaXDxZpsE4R7_JS5rGGLtqIKI8X7ooye4rkmCLaSwA2t3aiJAbfyQ0zz1JpQCsOUuRdQxuRmYchn/pub?gid=239992256&single=true&output=csv";

const Main = styled.div<any>`
  height: 100vh;
  background: url("${Bkg}") #f4f2ff no-repeat fixed left bottom;
  display: flex;
  overflow: clip;
  background-size: 25vw;
`

const Header = styled.div`
  color: #6a6ba7;
  flex-grow: 1;
  margin-left: 10vw;
  margin-top: 16vh;
`
const AmazonTitle = styled.div`
  font-weight: 700;
  font-size: 4vw;
`
const PoweredBy = styled.div`
  padding-top: 1vw;
  padding-bottom: 1vw;
  font-size: 1.5vw;
`
const Logos = styled.div`
  display: flex;
  flex-direction: rows;
  img {
    width: 12vw;
  }
`
const Items = styled.div`
  flex-grow: 3;
  font-size:1.5vw;
  transition: margin-top 1s linear;
`
const ItemWrapper = styled.div`
  margin: 0.5vh;
  padding-top: 1.0vh;
`
const Item = styled.div`
  background-color: white;
  border-radius: 1.0vw;
  padding: 1.5vw;
`
const ItemGroup = styled.div`
  display: flex;
  flex-direction: rows;
  justify-content: space-evenly;
`

function renderImage(item:Blah){
  const imgId = (new URL(item["Pic"])).searchParams.get("id");
  const src = `https://drive.google.com/uc?export=view&id=${imgId}`;
  return <div>{item["E-Mail"]} - {item["Pic"]} - {item["Timestamp"]} - { item["Amazon Spend"]}<img alt="Bored Ape" src={src}/></div>
}

const testData = [
  { name: "@amazongawd", spend: 40000, idx:0 },
  { name: "amazillionaire", spend: 13528.20, idx:0 },
  { name: "joff bazos", spend: 39876.78, idx:0 },
  { name: "1337", spend: 1235.6789, idx:0 },
  { name: "zug", spend: 50.75, idx:0 },
  { name: "best buyer", spend: 4822, idx:0 },
  { name: "mr kroger", spend: 39492, idx:0 },
  { name: "honey, I shrunk  the tokens very longly", spend: 24213, idx:0 },
  { name: "tupac", spend: 0, idx:0 },
  { name: "shakur", spend: 0, idx:0 },
  { name: "is", spend: 0, idx:0 },
  { name: "alive", spend: 0, idx:0 },
]

const usd = new Intl.NumberFormat('en', { style:'currency', currency:'USD', maximumFractionDigits: 0}).format

function renderItem(item:Entry, listPos:number){
  const idx = item.idx;
  const i = idx === 0 ? "🏆" : `#${idx+1}`
  return <ItemWrapper key={`item_${listPos}_${idx}`}>
    <Item>
      <div style={{display:"flex", alignItems:"baseline"}}>
        <div style={{backgroundColor:"#f4f2ff", borderRadius: "1vw", padding: "1vw"}}>{i}</div>
        <div style={{paddingLeft:"1vw"}}><b>{usd(item.spend)}</b></div>
      </div>
      <div style={{paddingTop: "1vh"}}>{item.name}</div>
    </Item>
  </ItemWrapper>;
}

function filterRaw(item:RawEntry){
  return item["Deleted"] === ""  && item["Amazon 3-Year Spend"] !== "";
}
function convertRaw(item:RawEntry):Entry{
  return { name: item["User Name (or alias)"], spend: Number(item["Amazon 3-Year Spend"].replace(/\$|,/g, '')), idx: 0 };
}

const header = <Header>
  <AmazonTitle>
    <div>Amazon</div>
    <div>Spending</div>
    <div>Leaderboard</div>
    <div>2021-2023</div>
  </AmazonTitle>
  <PoweredBy>Powered By</PoweredBy>
  <Logos>
    <div><img src={UskoLogo} alt="Usko Logo" /></div>
    <div><img src={AmazonLogo} alt="Amazon Logo" style={{paddingLeft: "1.5vw", paddingTop:"1.70vh"}}/></div>
  </Logos>
</Header>;

function useInterval(callback : () => void, delay:number|null) {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function App() {
  const [sheet, setSheet] = React.useState('');
  const [prevSheets, setPrevSheets] = React.useState(new Set<string>());
  const [hasRan, setHasRun] = React.useState(false);

  useInterval(async () => {
    const now = new Date();
    const url = sheetURL+"&cacheBuster="+now.getTime();
    const res = await fetch(url);
    const t = await res.text();
    console.log("Fetched", now.toString());
    if (!res.ok || t.startsWith("<")) {
      // sometimes google sends us a 400 and/or HTML for some reason
      console.log("Unexpected fetch response", res.status, "\n", t);
    } else if (!prevSheets.has(t)) {
      // work around google caching being broken and returning old data
      prevSheets.add(t);
      setPrevSheets(prevSheets);
      setSheet(t);
    }
  }, 1000);

  const zz = React.useMemo(() => {
    const parsedSheet = Papa.parse(sheet, { header: true});
    console.log("Parsed", parsedSheet.data);
    return (parsedSheet.data as RawEntry[]).filter(filterRaw).map(convertRaw);
  }, [sheet]);
  //const zz = testData;
  const h = zz.sort((a, b) => b.spend - a.spend).map((e, i) => { e.idx = i; return e });
  const a1 = h.filter((e, i) => i % 2 === 0);
  const a2 = h.filter((e, i) => i % 2 === 1);
  const c1 = ([...a1]).reverse().slice(0, 4).reverse().concat(a1.concat([...a1])).map(renderItem);

  const b1 = [...a1].reverse().slice(0, 4).reverse().concat(a1);
  const b2 = [...a2].reverse().slice(0, 4).reverse().concat(a2);

  const speed = hasRan ? Math.max(1, (c1.length / 6.0) * 10) : 0.1;

  const initialOffset = 0;
  const [topOffset, setTopOffset] = React.useState(initialOffset);

  useInterval(() => {
    setHasRun(true);
    if (topOffset === initialOffset)
      setTopOffset(c1.length < 5 ? initialOffset : -c1.length * (100 / 6.5));
    else
      setTopOffset(initialOffset);
  }, c1.length > 0 ? speed * 1000 : null);

  return (
    <Main>{header}
      <Items style={{marginTop: topOffset+"vh", transition:`margin-top ${speed}s linear`}}>
        <ItemGroup>
          <div style={{paddingTop:"1vw", width:"40%"}}>{b1.map(renderItem)}</div>
          <div style={{paddingTop:"5vh", width:"40%"}}>{b2.map(renderItem)}</div>
        </ItemGroup>
        <ItemGroup>
          <div style={{paddingTop:"1vw", width:"40%"}}>{a1.map(renderItem)}</div>
          <div style={{paddingTop:"5vh", width:"40%"}}>{a2.map(renderItem)}</div>
        </ItemGroup>
      </Items>
    </Main>
  );
}

export default App;
